/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useGetListingLotsQuery, useSubmitMultiSkuBidMutation } from '../../../components/api'
import { useGetListingLotSkusQuery } from '../../../components/api/BuyerApi'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { skuToDescription } from '../../../components/skuToDescription'
import Spinner from '../../../components/spinner'
import { isUndef, NOOP } from '../../../components/utils'

const isBlank = s => s.trim().length === 0

/**
 * Reponsible for display and updating the bid.
 * @param {*} props
 * @returns
 */
const PlaceBidInput = (props) => {
  const {
    listingId,
    listingLotId,
    listingLotSkuId,
    buyerId,
    updatedBids,
    doUpdateBid
  } = props

  const key = `${listingId}#${listingLotId}#${listingLotSkuId}#${buyerId}`

  // state
  const [amount, setAmount] = React.useState(undefined)

  React.useEffect(() => {
    if (!isUndef(updatedBids[key])) setAmount(updatedBids[key].newBid)
  }, [updatedBids, amount, key])

  // on update...
  const onUpdate = (e) => {
    const { value } = e.target
    if (isBlank(value)) {
      doUpdateBid(listingId, listingLotId, listingLotSkuId, buyerId, value)
    } else {
      const newBid = Math.round(value)
      if (!isNaN(newBid)) doUpdateBid(listingId, listingLotId, listingLotSkuId, buyerId, newBid)
    }
  }

  return (<>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ height: '29px' }}>$</InputGroup.Text>
        </InputGroup.Prepend>
          <Form.Control
          size="sm"
          type="text"
          value={amount}
          onChange={e => onUpdate(e)}
          className='placebid'
          inputMode="numeric"
          />
      </InputGroup>

      {/* { process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(amount, null, 2)}</pre>} */}
      </>
  )
}

/**
 *
 * ANCHOR Page
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '325' } = props
  //   const { listingId } = getQueryParams(props)

  // query
  const submitMultiBidMutation = useSubmitMultiSkuBidMutation()
  const listingLotQuery = useGetListingLotsQuery(listingId)
  const { data: { buyers = [], listing_lots: listingLots = [] } = {} } = listingLotQuery
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: 'all', listingLots, isVendor: true })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot
  const getSkuRow = (meta) => {
    return listingLotSkus[meta.currentTableData[meta.rowIndex].index]
  }

  // state
  const [selectedBuyers, setSelectedBuyers] = React.useState([])
  const [updatedBids, setUpdatedBids] = React.useState({})

  /**
   * Stages updates to the buyer's bids.
   * @param {*} listingId
   * @param {*} listingLotId
   * @param {*} buyerId
   * @param {*} newBid
   */
  const doUpdateBid = React.useCallback((listingId, listingLotId, listingLotSkuId, buyerId, newBid) => {
    setUpdatedBids(ps => {
      const key = `${listingId}#${listingLotId}#${listingLotSkuId}#${buyerId}`
      const { isUpdated, ...prevState } = ps
      const prevEntry = prevState[key]
      const updated = {
        ...prevState,
        [key]: { ...prevEntry, newBid, isUpdated: prevEntry.originalBid !== newBid }
      }
      // materialise the "isUpdated" flag...
      updated.isUpdated = Object.values(updated).some(bid => bid.isUpdated)
      return updated
    })
  }, [])

  // functions
  const selectBuyer = (val) => {
    setSelectedBuyers(val)
    setUpdatedBids({})
    submitMultiBidMutation.reset()
  }
  const onHide = () => {
    setSelectedBuyers([])
    setUpdatedBids({})
    setOpenModal(false)
    submitMultiBidMutation.reset()
  }
  const internalOnSave = () => {
    const { isUpdated, ...bids } = updatedBids
    const validBids = Object.values(bids).filter(bid => bid.isUpdated)
    // console.log('submitting bids', validBids)
    submitMultiBidMutation.mutate(validBids)
    setSelectedBuyers([])
    setUpdatedBids({})
  }

  React.useEffect(() => {
    // (important!)
    const hasNotYetBeenInitialised = Object.keys(updatedBids).length === 0
    if (listingLotSkus.length > 0 && selectedBuyers.length > 0 && hasNotYetBeenInitialised) {
      // initiliase the updateBids object...
      const buyerId = selectedBuyers[0].company_id
      const map = listingLotSkus.reduce((prev, curr) => {
        const { listing_lot_sku_id: listingLotSkuId = null, buyer_bids = [] } = curr
        const { id: listingLotId } = listingLots.find(l => l.lot_number === curr.listing_lot_number)
        const bidEntry = buyer_bids.find(c => c.company_id === buyerId)
        const originalBid = bidEntry ? Math.round(bidEntry.price) : ''
        const newBid = originalBid
        prev[`${listingId}#${listingLotId}#${listingLotSkuId}#${buyerId}`] = {
          listingId,
          listingLotId,
          listingLotSkuId,
          buyerId,
          newBid,
          originalBid,
          isUpdated: false
        }
        return prev
      }, { isUpdated: false })
      setUpdatedBids(map)
    }
  }, [listingLots, listingLotSkus, listingId, selectedBuyers, updatedBids])

  return <>

        <Modal size="lg" show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Edit bids</h5>
                <p>Select a buyer to edit their bids.</p>

                { listingLotQuery.isLoading
                  ? <div className="d-flex justify-content-center m-4"><Spinner /></div>
                  : <>
            {/* choose buyer */}
            <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                options={buyers}
                placeholder="Select a buyer"
                onChange={selectBuyer}
                selected={selectedBuyers}
                className="mb-3"
            />

            {/* choose lot */}
            { selectedBuyers.length > 0 && listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
            { selectedBuyers.length > 0 && listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
            { selectedBuyers.length > 0 && listingLotSkusQuery.isSuccess && <MUIDataTable
                // title={'Employee List'}
                data={listingLotSkusQuery.data.listing_lot.listing_lot_skus}
                columns={[
                  { label: 'Lot', name: 'listing_lot_number' },
                  { label: 'Description', name: 'description', options: { customBodyRender: (x, meta) => skuToDescription(getSkuRow(meta)) } },
                  { label: 'Grade', name: 'grade' },
                  { label: 'Quantity', name: 'qty' },
                  {
                    label: 'Subtotal',
                    name: 'subtotal',
                    options: {
                      customBodyRender: (x, meta) => {
                        const data = getSkuRow(meta)
                        const lot = listingLots.find(l => l.lot_number === data.listing_lot_number)
                        // const price = '2'
                        const buyerId = selectedBuyers.length > 0 ? selectedBuyers[0].company_id : null
                        const key = `${listingId}#${lot.id}#${data.listing_lot_sku_id}#${buyerId}`
                        const bid = updatedBids[key]
                        return bid && bid.newBid ? `$${(data.qty * bid.newBid).toLocaleString()}` : ''
                      }
                    }
                  },
                  {
                    label: 'Bid',
                    name: 'id',
                    options: {
                      customBodyRender: (x, meta) => {
                        const data = getSkuRow(meta)
                        const lot = listingLots.find(l => l.lot_number === data.listing_lot_number)
                        return <PlaceBidInput
                            listingId={listingId}
                            listingLotId={lot.id}
                            listingLotSkuId={data.listing_lot_sku_id}
                            buyerId={selectedBuyers.length > 0 ? selectedBuyers[0].company_id : null}
                            updatedBids={updatedBids}
                            doUpdateBid={doUpdateBid}
                            />
                      }
                    }
                  }
                ]}
                options={{
                  selectableRows: 'none',
                  pagination: true,
                  jumpToPage: false,
                  rowsPerPage: 15,
                  print: false,
                  search: true,
                  download: false,
                  sort: false,
                  filter: false,
                  viewColumns: false,
                  elevation: 0,
                  setTableProps: () => ({ size: 'small' })
                }}
                />
              }

          </>}

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={selectedBuyers.length === 0 || !updatedBids.isUpdated}>
                        {submitMultiBidMutation.isLoading ? <Spinner /> : <span>Save</span>}
                    </Button>
                </div>

                { submitMultiBidMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Change successful!</Alert> }
                { submitMultiBidMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating bids. ' + submitMultiBidMutation.error.message}</Alert> }

                { process.env.NODE_ENV !== 'production' && <>
                    <pre>updatedBids = {JSON.stringify(updatedBids, null, 2)}</pre>
                    <pre>listingLotQuery = {JSON.stringify(listingLotQuery, null, 2)}</pre>
                </>}
            </Modal.Body>
        </Modal>
      </>
}

export default Page
